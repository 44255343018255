<!-- Name -->
<script lang="ts">
export default {
    name: 'app-header'
};
</script>

<script lang="ts" setup>
import { useModalStore } from '~/store/modal';

const { isMobileSize } = useDeviceFunctions();
const modalStore = useModalStore();
const { showHideModal } = modalStore;
const isShowMenu = ref(false);
const contactForm = resolveComponent('ui/modals/app-contact-form');
const loginModal = resolveComponent('ui/modals/app-login');

const {isAuthenticated, user } = useSanctumAuth();


const toogleMenu = () => {
    isShowMenu.value = !isShowMenu.value;
};

const showModalContact = () => {
    const payload = {
        showModal: true,
        component: contactForm,
        size: 'full-mobile'
    };

    showHideModal(payload);
};

const showModalLogin = async () => {


  if(!isAuthenticated.value){
    const payload = {
    showModal: true,
    component: loginModal,
    size: "full-mobile",
    props: {
        isBrand: false
    }
    };
    showHideModal(payload);

  }
  else{
    return await navigateTo({ path: '/panel' });
  }

};


watchEffect(() => {
    if (process.client) {
        if (isShowMenu.value) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }
});
</script>

<template lang="pug">
header.app-header
  .container.container-big
    .row
      .col
        nav.app-header__nav
          .d-flex.gap-3
            span.icon-menu.d-lg--custom-none(@click="toogleMenu")
            nuxt-link.app-header__logo(to="/") 
              img.img-fluid(
                src="~/assets/images/logo-white-marcos-automocion.png", 
                alt="Logo Marcos Automoción")
          ul.app-header__list(:class="{'show': isShowMenu}") 
            span.icon-times.text--tertiary.d-lg--custom-none(@click="toogleMenu")
            li.app-header__li.app-header__li-submenu 
              span.app-header__link.d-lg--custom-show Grupo
              .accordion.accordion-flush.d-lg--custom-none(id="header-submenu-mobile")
                .accordion-item
                  p.accordion-header(id="flush-heading1")
                    button.accordion-button.collapsed(type="button", data-bs-toggle="collapse", data-bs-target="#flush-collapse1", aria-expanded="false", aria-controls="flush-collapse1") Grupo
                  .accordion-collapse.collapse(id="flush-collapse1", aria-labelledby="flush-heading1", data-bs-parent="#header-submenu-mobile")
                    .accordion-body
                      ul.app-header__sublist-mobile 
                        li.app-header__li 
                          nuxt-link.app-header__link(to="/historia/") Historia
                        li.app-header__li 
                          nuxt-link.app-header__link(to="/trabaja-con-nosotros/") Trabaja con nosotros
                        li.app-header__li 
                          nuxt-link.app-header__link(to="/sostenibilidad/") Sostenibilidad
                        li.app-header__li 
                          nuxt-link.app-header__link(to="/instalaciones/") Sedes
                        li.app-header__li 
                          nuxt-link.app-header__link(to="/alianzas/") Alianzas
              ul.app-header__sublist.d-lg--custom-show
                li.app-header__li 
                  nuxt-link.app-header__link(to="/historia/") Historia
                li.app-header__li 
                  nuxt-link.app-header__link(to="/trabaja-con-nosotros/") Trabaja con nosotros
                li.app-header__li 
                  nuxt-link.app-header__link(to="/sostenibilidad/") Sostenibilidad
                li.app-header__li 
                  nuxt-link.app-header__link(to="/instalaciones/") Sedes
                li.app-header__li 
                  nuxt-link.app-header__link(to="/alianzas/") Alianzas
            li.app-header__li 
              button.btn.btn--outlined-white
                nuxt-link(to="https://www.modrive.com/" target="_blank")
                  span
                    img(
                    src="/images/logo-modrive-horizontal.svg", 
                    alt="MODRIVE", 
                    width="110")
            li.app-header__li.app-header__li-submenu  
              span.app-header__link.d-lg--custom-show Stock VN
              .accordion.accordion-flush.d-lg--custom-none(id="header-submenu-mobile-nuevos")
                .accordion-item
                  p.accordion-header(id="flush-heading2")
                    button.accordion-button.collapsed(type="button", data-bs-toggle="collapse", data-bs-target="#flush-collapse2", aria-expanded="false", aria-controls="flush-collapse2") Stock VN
                  .accordion-collapse.collapse(id="flush-collapse2", aria-labelledby="flush-heading2", data-bs-parent="#header-submenu-mobile-nuevos")
                    .accordion-body
                      ul.app-header__sublist-mobile 
                        li.app-header__li 
                          nuxt-link.app-header__link(to="/coches-nuevos/") Stock VN
                        li.app-header__li.mb-0 
                          nuxt-link.app-header__link(to="/coches-nuevos/kilometro-cero/") Stock KM0
              ul.app-header__sublist.d-lg--custom-show 
                li.app-header__li 
                  nuxt-link.app-header__link(to="/coches-nuevos/") Stock VN
                li.app-header__li.mb-0 
                  nuxt-link.app-header__link(to="/coches-nuevos/kilometro-cero/") Stock KM0           
            li.app-header__li 
              nuxt-link.app-header__link(to="/promociones-marcas/") Promociones Venta
            li.app-header__li.app-header__li-submenu  
              span.app-header__link.d-lg--custom-show Eléctricos
              .accordion.accordion-flush.d-lg--custom-none(id="header-submenu-mobile-electricos")
                .accordion-item
                  p.accordion-header(id="flush-heading3")
                    button.accordion-button.collapsed(type="button", data-bs-toggle="collapse", data-bs-target="#flush-collapse3", aria-expanded="false", aria-controls="flush-collapse3") Eléctricos
                  .accordion-collapse.collapse(id="flush-collapse3", aria-labelledby="flush-heading3", data-bs-parent="#header-submenu-mobile-electricos")
                    .accordion-body
                      ul.app-header__sublist-mobile 
                        li.app-header__li 
                          nuxt-link.app-header__link(to="/coches-electricos/") Stock eléctricos
                        li.app-header__li.mb-0 
                          nuxt-link.app-header__link(to="/noticias-electricos/") Noticias eléctricos
              ul.app-header__sublist.d-lg--custom-show 
                li.app-header__li 
                  nuxt-link.app-header__link(to="/coches-electricos/") Stock eléctricos
                li.app-header__li 
                  nuxt-link.app-header__link(to="/noticias-electricos/") Noticias eléctricos
            li.app-header__li 
              nuxt-link.app-header__link(to="/movilidad/") Movilidad
            li.app-header__li 
              nuxt-link.app-header__link(to="/servicios-empresas/") Empresas
            li.app-header__li 
              nuxt-link.app-header__link(to="/seguros/") Seguros
            li.app-header__li.app-header__li-submenu  
              span.app-header__link.d-lg--custom-show Posventa
              .accordion.accordion-flush.d-lg--custom-none(id="header-submenu-mobile-postventa")
                .accordion-item
                  p.accordion-header(id="flush-heading4")
                    button.accordion-button.collapsed(type="button", data-bs-toggle="collapse", data-bs-target="#flush-collapse4", aria-expanded="false", aria-controls="flush-collapse4") Posventa
                  .accordion-collapse.collapse(id="flush-collapse4", aria-labelledby="flush-heading3", data-bs-parent="#header-submenu-mobile-posventa")
                    .accordion-body
                      ul.app-header__sublist-mobile 
                        li.app-header__li 
                          nuxt-link.app-header__link(to="/promociones-posventa/") Promociones
                        li.app-header__li 
                          nuxt-link.app-header__link(to="/cita-previa-taller/") Cita previa
                        li.app-header__li.mb-0 
                          nuxt-link.app-header__link(to="/centros-chapa-y-pintura/") Chapa y pintura
              ul.app-header__sublist.d-lg--custom-show 
                li.app-header__li 
                  nuxt-link.app-header__link(to="/promociones-posventa/") Promociones
                li.app-header__li 
                  nuxt-link.app-header__link(to="/cita-previa-taller/") Cita previa
                li.app-header__li 
                  nuxt-link.app-header__link(to="/centros-chapa-y-pintura/") Chapa y pintura
            li.app-header__li 
              nuxt-link.app-header__link(to="/noticias/") Actualidad
          ul.app-header__personal-area 
            //- li.app-header__li
            //-   nuxt-link(to="/favoritos") 
            //-     span.icon-heart
            li.app-header__li(@click="showModalLogin") 
              //nuxt-link(to="/registro/") 
              span.icon-user
            li.app-header__li
              button.btn.btn--tertiary.btn--small(@click="showModalContact") Contacto
</template>

<style lang="scss" scoped>
@import 'index';
</style>
