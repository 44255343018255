<!-- Name -->
<script lang="ts">
export default {
  name: 'app-footer'
}
</script>

<script lang="ts" setup>

</script>

<template lang="pug">
.app-footer
  .container
    .row.justify-content-between.align-items-center 
      .col-lg-4
        .app-footer__left.d-flex.flex-column.flex-lg-row.justify-content-center.align-items-center.gap-4 
          .app-footer__logo
            nuxt-link(to="/")
              img.img-fluid(
                src="~assets/images/marcos-automocion-logo-iso.png", 
                alt="Marcos Automocion logotipo", 
                width="93")
          span.text-center.text-lg-start Marcos Automoción, una empresa con amplia experiencia y contrastada confianza, avalada por una trayectoria de más de 50 años, siendo una de las compañías más fuertes en el campo de la automoción a nivel nacional.
      .col-lg-4.my-4.my-lg-0
        .app-footer__middle.text-center 
          ul
            li.mb-lg-2(v-if="!$config.public.pmvMode")
              nuxt-link(to="/promociones-marcas/") 
                span.icon-poligon-right.text--tertiary
                span Marcas
            li.mb-lg-2
              nuxt-link(to="/cita-previa-taller/") 
                span.icon-poligon-right.text--tertiary
                span Taller
            li
              nuxt-link(to="/instalaciones/") 
                span.icon-poligon-right.text--tertiary
                span Instalaciones
      .col-lg-4 
        .app-footer__right
          span Redes Sociales
          ul
            li
              a(
                href="https://www.facebook.com/MarcosAutomocion", 
                target="_blank", 
                rel="noopener") 
                span.icon-facebook
            li
              a(
                href="https://www.instagram.com/marcosautomocion_/", 
                target="_blank", 
                rel="noopener") 
                span.icon-instagram
            li
              a(
                href="https://www.youtube.com/channel/UCIlFHnK0vSop2KqWmOM32tg", 
                target="_blank", 
                rel="noopener") 
                span.icon-youtube
            li
              a(
                href="https://www.linkedin.com/company/grupo-marcos-automocion", 
                target="_blank", 
                rel="noopener") 
                span.icon-linkedin
            li
              a(
                href="https://www.tiktok.com/@marcosautomocion ", 
                target="_blank", 
                rel="noopener") 
                span.icon-tiktok
    hr
  .app-footer__bottom
    .container 
      .row.flex-column-reverse.flex-lg-row
        .col-lg-6.text-center.text-lg-start 
          small.d-block Powered By 
            nuxt-link(to="https://www.tilomotion.com/" target="_blank").app-footer__bottom-tilo Tilo Motion
          small &copy;  2024 Marcos Automoción - Todos los derechos reservados
        .col-lg-6 
          ul
            li
              nuxt-link(to="/politica-de-cookies/") Política de cookies
            li
              nuxt-link(to="/aviso-legal/") Aviso legal
            li 
              nuxt-link(to="/politica-de-privacidad/") Política de privacidad
            li
              nuxt-link(to="/canal-denuncias/") Canal de denuncias
          hr.d-lg-none
</template>

<style lang="scss">
@import 'index';
</style>