<script lang="ts">
export default {
  name: 'app-contact-form-modal'
}
</script>

<script lang="ts" setup>
import { useModalStore } from '~/store/modal';
import { useSpinnerStore } from '@/store/spinner';
import { useBrandsInfoStore } from '~/store/brandsInfo'; 

const modalStore = useModalStore();
const spinnerStore = useSpinnerStore();
const brandsInfoStore= useBrandsInfoStore();

const route = useRoute();
const runtimeConfig = useRuntimeConfig();

const { hideModal,modal } = modalStore;

const uuid= uuis();

const fields = {
    nombre: {
        value: '',
        validations: ['required', 'string']
    },
    telefono: {
        value: '',
        validations: ['required', 'phone']
    },
    departamento_sede: {
        value: '',
        validations: ['required']
    },
    marca: {
        value: '',
        validations: ['brand']
    },
    mensaje: {
        value: '',
        validations: ['required']
    },
    acepta_gdpr_1: {
        value: false,
        validations: ['required']
    },
    acepta_gdpr_2: {
        value: false,
        validations: []
    }
};

const brandsNames=brandsInfoStore.getActiveBrandsOrdered().map(brand => brand.nombre_comercial);
const { formData, formStates, addKey,removeKey, validateData } = useFormsMa(fields);

/**
 * Valida datos del formulario y si es correcto los envía
 */
async function sendForm() {
    const router = useRouter();
    const url = useRequestURL();
    const { $api } = useNuxtApp();
    
    if(formData.departamento_sede !== 'ventas'){
      removeKey('marca');
    };

    const { isOk, formData:formDataVal } = validateData();


    if (isOk) {
      
        formDataVal.append('form', 'contacto');
        formDataVal.append('path', url.href);
        formDataVal.append('pagina', '');
        formDataVal.append('tipo_contacto',' web_callme');
        spinnerStore.showSpinner();

        const { data, status, error } = await $api.forms.store(formDataVal);

        spinnerStore.hideSpinner();

        if (error.value) {
          throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
            return;
        }
        hideModal();
        //router.push('/gracias');
        await navigateTo({
          path: '/gracias/',
          query: {
            form:'form_contacto',
          }
        });
    }
}

watch(
  ()=>formData.departamento_sede,
  async (newValue)=>{
  if(newValue!=='ventas'){
    removeKey('marca');
  }else{
    addKey('marca');
  }
});

</script>

<template lang="pug">
.contact-form-modal.modal-custom__inner
  .contact-form-modal__header 
    p.h3 Contacto 
    span.icon-times.text--tertiary(@click="hideModal()")
  hr
  .contact-form-modal__body 
    p.h4 Teléfono de atención al cliente. Llámanos
    a.large-text(href="tel:+34966736763" ) 966 736 763
    router-link.btn-tertiary.mt-3(to="/instalaciones/" @click="hideModal()") Ver todas las sedes
    hr.dashed
    //-p.h4 ¿Necesitas ayudas? Escríbenos
      a.large-text(href="mailto:info@marcosautomocion.com") info@marcosautomocion.com
      hr.dashed
    p.h4 ¿Necesitas ayuda?
      br
      | Deja tus datos y nos pondremos en contacto contigo
    form.mt-4.floatingForm
      .form-floating.mb-3
        input.form-control.form-control--border-primary(
          type="text", 
          :id="`nombre${uuid}`",
          placeholder="Nombre",
          v-model="formData.nombre")
        label(:for="`nombre${uuid}`") Nombre
        span.text-danger(v-if="formStates.nombre") {{ formStates.nombre }}
      .form-floating.mb-3
        input.form-control.form-control--border-primary(
          type="tel", 
          :id="`telefono${uuid}`",
          placeholder="Teléfono",
          v-model="formData.telefono")
        label(:for="`telefono${uuid}`") Teléfono
        span.text-danger(v-if="formStates.telefono") {{ formStates.telefono }}
      .form-floating.mb-3
        select.form-select.form-control--border-primary(
          :id="`department${uuid}`",
          v-model="formData.departamento_sede"
        )
            option(value="") Elige Departamento
            option(value="ventas") Ventas
            option(value="Posventa") Posventa
            option(value="Seguros") Seguros
            option(value="Empresas") Empresas
        label(:for="`department${uuid}`") Departamento
        span.d-block.text-danger(v-if="formStates.departamento_sede") {{ formStates.departamento_sede }}
      .form-floating.mb-3(v-if="formData.departamento_sede === 'ventas'")
        select.form-select.form-control--border-primary(
          :id="`brand${uuid}`",
          v-model="formData.marca"
        )
            option(value="") Elige una marca
            option(v-for="(item, index) in brandsNames" :key="index" :value="item") {{item}}
        label(:for="`brand${uuid}`") Marca
        span.d-block.text-danger(v-if="formStates.marca") {{ formStates.marca }}       
      .form-floating.mb-3
        textarea.form-control.form-control--border-primary(
          :id="`message${uuid}`", 
          placeholder="Mensaje"
          style="height: 100px",
          v-model="formData.mensaje"
          )
        label(:for="`message${uuid}`") Comentarios
        span.text-danger(v-if="formStates.mensaje") {{ formStates.mensaje }}
      .form-check.mb-1
        input.form-check-input(
          type="checkbox", 
          :id="`acceptances${uuid}`",
          v-model="formData.acepta_gdpr_1")
        label(:for="`acceptances${uuid}`") He leído y acepto los 
          nuxt-link.termsLink(target="_blank", to="/politica-de-privacidad/") términos y condiciones de la política de privacidad.
        span.d-block.text-danger(v-if="formStates.acepta_gdpr_1") {{ formStates.acepta_gdpr_1 }}
      .form-check
        input.form-check-input(
          type="checkbox", 
          :id="`consent${uuid}`",
          v-model="formData.acepta_gdpr_2"
        )
        label(:for="`consent${uuid}`") Doy mi consentimiento para el tratamiento de mis datos personales con fines de marketing y comerciales
      button.btn.btn--primary.btn--large.mt-4(@click.prevent="sendForm()") Enviar
</template>

<style lang="scss">
@import 'index';
</style>